import { FirebaseOptions } from "firebase/app";

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyCoHrtcVlwSCc1lwfAYAqlziXH3hE72ps0",
  authDomain: "kidletcareparentauth.firebaseapp.com",
  databaseURL: "https://kidletcareparentauth-default-rtdb.firebaseio.com",
  projectId: "kidletcareparentauth",
  storageBucket: "kidletcareparentauth.appspot.com",
  messagingSenderId: "1045454681917",
  appId: "1:1045454681917:web:70323f8ebb05efe8a0b754",
  measurementId: "G-Z5LXDXZYRW"
};

export default firebaseConfig;